import { navigate } from "gatsby"
import React from "react"
import Layout from "~/components/Layout"
import PreviewImage from "~/components/reusable/PreviewImage"
import Spacer from "~/components/reusable/svgs/Spacer"
import Wrapper from "~/components/reusable/Wrapper"
import Seo from "~/components/SEO"
import { ThankYouWrapper } from "~/components/ThankYou"
import useAccountManager from "~/hooks/useAccountManager"
import useTemplateContent from "~/hooks/useTemplateContent"
import { isBrowser, replaceEmail, replaceName } from "~/utils/helpers"

const ThankYou = ({ location }: { location: any }) => {
  if (!isBrowser()) {
    return null
  }
  const { state } = location

  const signupType = state?.signupType

  const content =
    signupType === "online"
      ? useTemplateContent("signUpThankYou")
      : signupType === "callback"
      ? useTemplateContent("callBackThankYou")
      : null

  const meta =
    signupType === "online"
      ? useTemplateContent("thankYouSignUpMeta")
      : signupType === "callback"
      ? useTemplateContent("thankYouCallbackMeta")
      : null

  if (!state?.formSubmitted) {
    navigate("/join-us")
    return null
  }

  // if state.emailAddress exists, show the email address in content.text
  // otherwise, show the default content.text
  const text = state?.emailAddress
    ? replaceEmail(content.text, state.emailAddress)
    : content.text

  const {
    data: accountManager,
    loading,
    error,
  } = useAccountManager(state?.postcode)

  return (
    <Layout path="/join-us/thank-you/">
      <Seo title={meta?.title} description={meta?.description} />
      <div
        className={`relative mt-[-153px] overflow-x-hidden bg-theme-yellow pt-32`}
      >
        <div
          className={`dot absolute right-[-160px] top-[-170px] z-[0] h-[550px] w-[550px] rounded-full bg-theme-green md:right-[-320px] md:top-[-340px] md:h-[1058px] md:w-[1058px]`}
        />
        <Wrapper className={`relative z-[2]`}>
          <ThankYouWrapper
            content={content}
            leftContent={
              <div className=" px-3">
                {signupType === "callback" && accountManager ? (
                  <h3 className="text-h3">{accountManager?.title}</h3>
                ) : null}
                <h2 className="mb-3 text-h2 font-bold tracking-tight md:text-h1">
                  {content.title}
                </h2>
                <p className={`leading-8 [&_a]:font-bold`}>{text}</p>
              </div>
            }
            rightContent={
              <>
                {content.steps.map((step: { text: string }, index: number) => {
                  const text = accountManager
                    ? replaceName(step.text, accountManager.title)
                    : step.text

                  return (
                    <div
                      key={index}
                      className={`mt-4 flex items-center rounded-3xl border py-[6px] ${
                        index === 0
                          ? `border-theme-greenlighter bg-theme-green-lighter`
                          : `border-theme-orange`
                      }`}
                    >
                      <span
                        className={`count w-14 text-center text-h4 leading-[72px] ${
                          index === 0 ? `text-theme-green` : `text-theme-orange`
                        }`}
                      >
                        0{index + 1}
                      </span>
                      <div
                        className={`mr-6 w-0.5 flex-shrink-0 self-stretch bg-theme-orange ${
                          index === 0 ? `bg-theme-green` : `bg-theme-orange`
                        }`}
                      ></div>
                      <div
                        className={`max-w-[204px] text-normal tracking-[-0.016em]`}
                      >
                        {step?.addTeamMember ? (
                          <div>
                            <img
                              src={
                                accountManager?.featuredImage?.node
                                  ?.mediaItemUrl
                              }
                              alt={`${accountManager?.title} photo`}
                              className={`mx-auto my-4 h-24 w-24 rounded-full`}
                            />
                            {/* TODO: replace with gatsby image? */}
                            {/* <PreviewImage
                              alt={accountManager?.title}
                              fallbackUrl={
                                accountManager?.featuredImage?.node?.localFile
                                  ?.publicUrl
                              }
                              image={
                                accountManager?.featuredImage?.node?.localFile
                              }
                              className={`mx-auto my-4 h-24 w-24 rounded-full`}
                            /> */}
                            <span>{text ? text : step.text}</span>
                          </div>
                        ) : (
                          <span className="font-bold">
                            {text ? text : step.text}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            }
          />
        </Wrapper>
        <Spacer top={`yellow`} bottom={`black`} />
      </div>
    </Layout>
  )
}

export default ThankYou
